<script>
  import { optionsStore } from "../store.js";
  import { onDestroy, onMount } from "svelte";
  import { get } from "svelte/store";
  import {
    setError,
    shortenString,
    copyToClipboard,
  } from "../shared/utils/utils.svelte";
  import * as NetworkChain from "@streamable-finance/sdk-core";
  import {
    faArrowUpRightFromSquare,
    faCopy,
    faGears,
  } from "@fortawesome/free-solid-svg-icons";
  import { faFlag } from "@fortawesome/free-regular-svg-icons";
  import Fa from "svelte-fa";

  export let sessionId;

  const options = get(optionsStore);
  const BSC_MAINNET_CHAIN_ID = parseInt(process.env.BSC_MAINNET_CHAIN_ID);
  const BSC_TESTNET_CHAIN_ID = parseInt(process.env.BSC_TESTNET_CHAIN_ID);
  const POLL_INTERVAL = 20000;
  const trxStatusesOrder = {
    CREATED: 1,
    INITIATED: 2,
    SENT_TO_MEMPOOL: 3,
    COMPLETED: 4,
  };
  const trxProcessingStatuses = [
    { status: "INITIATED", label: "Processing", order: 2 },
    { status: "SENT_TO_MEMPOOL", label: "Sent to Blockchain", order: 3 },
    { status: "COMPLETED", label: "Transaction is executed", order: 4 },
  ];

  let transaction;
  let trxUrl;

  /**
   * Returns if the transaction status to display has to be styled as completed or not
   * @param trxStatus - fetched transaction status
   * @param processingTrxStatus - transaction status to be displayed
   * @param processingTrxStatusOrder - order/weight of the transaction status to be displayed
   */
  const isProcessingStatusCompleted = (
    trxStatus,
    processingTrxStatus,
    processingTrxStatusOrder,
  ) => {
    const trxStatusOrder = trxStatusesOrder[trxStatus];

    return (
      processingTrxStatus === trxStatus ||
      processingTrxStatusOrder <= trxStatusOrder
    );
  };

  const getTrxBlockchainUrl = (trxHash, chainId) => {
    switch (chainId) {
      case NetworkChain.BSC_TESTNET_CHAIN_ID:
        return "https://testnet.bscscan.com/tx/" + trxHash;

      case NetworkChain.GNOSIS_CHAIN_ID:
        return "https://gnosisscan.io/tx/" + trxHash;

      case NetworkChain.BSC_MAINNET_CHAIN_ID:
      default:
        return "https://bscscan.com/tx/" + trxHash;
    }
  };

  const getTrxStatusData = async () => {
    try {
      // poll on payment session
      const res = await options.fetchPaymentDataBySessionId(sessionId);

      if (!res) {
        setError(new Error("Failed to fetch data"));
        return;
      }

      transaction = res;

      switch (transaction.cryptoNetwork) {
        case "BINANCE_MAIN_NET":
          trxUrl = getTrxBlockchainUrl(
            transaction.transactionHash,
            BSC_MAINNET_CHAIN_ID,
          );
          break;
        case "BINANCE_TEST_NET":
          trxUrl = getTrxBlockchainUrl(
            transaction.transactionHash,
            BSC_TESTNET_CHAIN_ID,
          );
          break;
        case "GNOSIS_MAIN_NET":
          trxUrl = getTrxBlockchainUrl(
            transaction.transactionHash,
            NetworkChain.GNOSIS_CHAIN_ID,
          );
          break;
      }
    } catch (err) {
      setError(new Error(err.message));
    }
  };

  // Set up polling on component mount
  onMount(async () => {
    await getTrxStatusData();
    const intervalId = setInterval(getTrxStatusData, POLL_INTERVAL);

    // Clean up interval on component destroy
    onDestroy(() => {
      clearInterval(intervalId);
    });
  });
</script>

<div class="trx column">
  {#if transaction}
    {#if transaction.status === "INITIATED"}
      <Fa icon={faGears} size="3x" color="#686bfc" />
      <div class="trx-title">Transaction is now processing</div>
      <div class="trx-subtitle">
        The transaction was confirmed and is now being processed
      </div>
    {:else}
      <Fa icon={faFlag} size="3x" color="#686bfc" />
      <div class="trx-title">Transaction was processed</div>
      <div class="trx-subtitle">It is now being indexed</div>
    {/if}
    <div class="trx-row">
      <div class="trx-point">1</div>
      <div class="trx-hash-container">
        <div class="trx-point-label">Your Transaction:</div>
        <div class="trx-hash-row">
          {#if transaction.transactionHash}
            <div class="trx-hash">
              {shortenString(transaction.transactionHash, 14, 14)}
            </div>
            <button
              on:click={() => copyToClipboard(transaction.transactionHash)}
              class="trx-icon-button"
              aria-label="Copy"><Fa icon={faCopy} /></button
            >
            <a
              href={trxUrl}
              target="_blank"
              class="trx-icon-button"
              aria-label="Go to Trx"><Fa icon={faArrowUpRightFromSquare} /></a
            >
          {:else}
            <div class="trx-hash">No hash found for this transaction</div>
          {/if}
        </div>
      </div>
    </div>
    <div class="trx-divider"></div>

    {#if transaction.status === "FAILED"}
      <div class="trx-row">
        <div class="trx-point">x</div>
        <div class="trx-point-label">
          Transaction failed, please contact the support team.
        </div>
      </div>
    {:else}
      <!-- From point 2 - INITIATED to point 4 - COMPLETED -->
      {#each trxProcessingStatuses as trxStatusData, index}
        <div class="trx-row">
          <div
            class={isProcessingStatusCompleted(
              transaction.status,
              trxStatusData.status,
              trxStatusData.order,
            )
              ? "trx-point"
              : "trx-point-outlined"}
          >
            {trxStatusData.order}
          </div>
          {#if trxStatusData.status === "SENT_TO_MEMPOOL" && transaction.streamHash}
            <div class="trx-hash-container">
              <div class="trx-point-label">{trxStatusData.label}</div>
              <div class="trx-hash-row">
                <div class="trx-hash">
                  {shortenString(transaction?.streamHash, 14, 14)}
                </div>
                <button
                  on:click={() => copyToClipboard(transaction.streamHash)}
                  class="trx-icon-button"
                  aria-label="Copy"><Fa icon={faCopy} /></button
                >
                <a
                  href={getTrxBlockchainUrl(
                    transaction.streamHash,
                    NetworkChain.GNOSIS_CHAIN_ID,
                  )}
                  target="_blank"
                  class="trx-icon-button"
                  aria-label="Go to Trx"
                  ><Fa icon={faArrowUpRightFromSquare} /></a
                >
              </div>
            </div>
          {:else}
            <div
              class={isProcessingStatusCompleted(
                transaction.status,
                trxStatusData.status,
                trxStatusData.order,
              )
                ? "trx-point-label"
                : "trx-point-label-disabled"}
            >
              {trxStatusData.label}
            </div>
          {/if}
        </div>
        {#if index < trxProcessingStatuses.length - 1}
          <div class="trx-divider"></div>
        {/if}
      {/each}
    {/if}
  {:else}
    <div class="title">No transaction data available.</div>
  {/if}
</div>

<style lang="scss">
  @import "../styles/styles.scss";
</style>
