import { Web3Auth } from "@web3auth/modal";
import {
  ADAPTER_EVENTS,
  CHAIN_NAMESPACES,
  WALLET_ADAPTERS,
} from "@web3auth/base";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";

/**
 * Web3AuthService help initialize Web3Auth sdk with minimal effort.
 * Main usage:
 *  await web3AuthService.initializeWeb3Auth();
 *  web3AuthService.subscribeAuthEvents(subscribersAuthEvents);
 *  await web3AuthService.connectWallet();
 *  // do actions now using provider web3AuthService.getWeb3AuthSdk(); which returns Web3Auth Provider
 *
 */
export class Web3AuthService {
  // general options for UI
  AUTH_MODE = "DAPP";

  // errors
  WEB3AUTH_INIT_ERROR = "Web3Auth SDK not initialized";

  /**
   * Constructor
   * @param web3AuthServiceConfig
   */
  constructor(web3AuthServiceConfig) {
    this.web3AuthServiceConfig = web3AuthServiceConfig;
  }

  /**
   * Connect to Wallet based on Web3Auth authorization sdk object
   * @return boolean connecting initialization status
   */
  async connectWallet() {
    if (!this.web3auth) throw new Error(this.WEB3AUTH_INIT_ERROR);

    return await this.web3auth.connect();
  }

  /**
   * Get Web3Auh config to initialize SDK.
   * Possibility to specify UI theme/logo/name etc.
   * @private
   */
  getWeb3AuthConfig() {
    return {
      chainConfig: {
        chainNamespace: CHAIN_NAMESPACES.EIP155,
        chainId: this.web3AuthServiceConfig.chainId,
        rpcTarget: this.web3AuthServiceConfig.rpcTarget,
        blockExplorer: this.web3AuthServiceConfig.blockExplorer,
        ticker: this.web3AuthServiceConfig.ticker,
        tickerName: this.web3AuthServiceConfig.ticker,
      },
      web3AuthNetwork: this.web3AuthServiceConfig.web3AuthNetwork,
      clientId: this.web3AuthServiceConfig.clientId,
      authMode: this.AUTH_MODE,
    };
  }

  /**
   * Initialize Web3Auth SDK
   * @return boolean initializing status
   */
  async initializeWeb3Auth() {
    try {
      const web3auth = new Web3Auth(this.getWeb3AuthConfig());
      this.configureAdapter(web3auth);
      await web3auth.initModal(this.getWeb3AuthModalConfig());
      this.web3auth = web3auth;
    } catch (error) {
      throw new Error(this.WEB3AUTH_INIT_ERROR);
    }
  }

  /**
   * Modal config mainly for External Auth Adapters: google, facebook etc.
   * @private
   */
  getWeb3AuthModalConfig() {
    return {
      modalConfig: {
        [WALLET_ADAPTERS.OPENLOGIN]: {
          label: "openlogin",
          loginMethods: {
            reddit: {
              name: "reddit Login",
              showOnModal: false,
            },
            twitch: {
              name: "Twitch Login",
              showOnModal: false,
            },
            apple: {
              name: "apple Login",
              showOnModal: false,
            },
            line: {
              name: "line Login",
              showOnModal: false,
            },
            github: {
              name: "github Login",
              showOnModal: false,
            },
            kakao: {
              name: "kakao Login",
              showOnModal: false,
            },
            weibo: {
              name: "weibo Login",
              showOnModal: false,
            },
            wechat: {
              name: "wechat Login",
              showOnModal: false,
            },
            sms_passwordless: {
              name: "sms_passwordless Login",
              showOnModal: false,
            },
          },
          showOnModal: true,
        },
      },
    };
  }

  /**
   * Configure Web3Auth Adapter settings if needed
   * @param web3auth
   * @private
   */
  configureAdapter(web3auth) {
    // only for domain-name settings (name and signature required)
    if (
      !this.web3AuthServiceConfig.domainName ||
      !this.web3AuthServiceConfig.domainSignature
    ) {
      return;
    }

    const whitelistedDomains = {
      [this.web3AuthServiceConfig.domainName]:
        this.web3AuthServiceConfig.domainSignature,
    };

    const openLoginAdapter = new OpenloginAdapter({
      adapterSettings: { originData: whitelistedDomains },
    });

    web3auth.configureAdapter(openLoginAdapter);
  }

  /**
   * Attach custom functions to execute code when sdk events fired
   * @param subscribeEvents
   * @return boolean attaching status
   */
  subscribeAuthEvents(subscribeEvents) {
    if (!this.web3auth) throw new Error(this.WEB3AUTH_INIT_ERROR);

    if (subscribeEvents.connectedFn)
      this.web3auth.on(ADAPTER_EVENTS.CONNECTED, subscribeEvents.connectedFn);
    if (subscribeEvents.connectingFn)
      this.web3auth.on(ADAPTER_EVENTS.CONNECTING, subscribeEvents.connectingFn);
    if (subscribeEvents.disconnectedFn)
      this.web3auth.on(
        ADAPTER_EVENTS.DISCONNECTED,
        subscribeEvents.disconnectedFn,
      );
    if (subscribeEvents.erroredFn)
      this.web3auth.on(ADAPTER_EVENTS.ERRORED, subscribeEvents.erroredFn);

    return true;
  }

  /**
   * Reloading sdk
   */
  reload() {
    if (!this.web3auth) throw new Error(this.WEB3AUTH_INIT_ERROR);

    this.web3auth.clearCache();
  }

  /**
   * Logout from sdk
   */
  async logout() {
    if (!this.web3auth) throw new Error(this.WEB3AUTH_INIT_ERROR);

    await this.web3auth.logout();
  }

  /**
   * Get Web3Auth sdk
   */
  getWeb3AuthSdk() {
    if (!this.web3auth) throw new Error(this.WEB3AUTH_INIT_ERROR);

    return this.web3auth;
  }
}
